import React from "react"
import "./src/styles/main.scss"
import "./src/styles/base.scss"

export const wrapRootElement = ({ element }) => {
  return (
    <>
      {element}
    </>
  )
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state)
    location.state.referrer = prevLocation ? prevLocation.pathname : null
}
